import Vue from 'vue'
import App from './App.vue'
import axios from 'axios';
import VueAxios from 'vue-axios'
import router from './router'
import vuetify from './plugins/vuetify';

window.axios = require('axios');
Vue.use(VueAxios, axios);

axios.interceptors.request.use(function (config) {
  if (config.url.startsWith('https://protomic.hr/admin/')){
    config.headers.accept = "application/json";
    config.headers.language = 'hr';
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
