<template>
  <v-app>
    <home-app-bar></home-app-bar>

    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>

    <Footer class="mb-9" />
    <v-footer
        absolute
        color="#fff"
    >
      <v-row>
        <v-col class="text-center">
          <span style="font-size: 14px">
            Copyright ©
            <strong>
              <a class="footer-link" href="#" target="_blank">ProTomic</a>
            </strong> {{ new Date().getFullYear() }}
          </span>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import HomeAppBar from "@/layouts/AppBar";
import Footer from "@/layouts/Footer";

export default {
  name: 'App',

  components: {
    HomeAppBar,
    Footer
  },

  data: () => ({
    //
  }),
};
</script>
