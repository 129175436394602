<template>
  <div>
    <v-app-bar
        id="home-app-bar"

        color="#003D73"
        elevation="1"
        height="100"
    >
      <router-link to="/">
        <v-img
            :src="require('@/assets/img/new_logo.png')"
            contain
            max-width="220"
            width="100%"
        />
      </router-link>

      <v-spacer/>

      <div>
        <v-tabs
            class="hidden-sm-and-down"
            optional
        >
          <v-tab
              v-for="(name, i) in items"
              :key="i"
              @click="$vuetify.goTo(name.to)"
              :exact="name.title === 'Naslovnica'"
              :ripple="false"
              active-class="white--text"
              class="font-weight-bold"
              style="background-color: #003D73; color: white !important;"
              min-width="96"
              text
          >
            {{ name.title }}
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
        v-model="drawer"
        :items="items"
    />
  </div>
</template>

<script>
export default {
  name: 'HomeAppBar',

  components: {
    HomeDrawer: () => import('./Drawer'),
  },

  data: () => ({
    drawer: null,
    items: [
      {
        title: 'Naslovnica',
        to: '#image',
      },
      {
        title: 'O nama',
        to: '#about',
      },
      {
        title: 'Blog',
        to: '#blog',
      },
      {
        title: 'Kontakt',
        to: '#contact',
      },
    ],
  }),
}
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
