<template>
  <v-footer color="#212121">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <div class="title mb-5 white--text">
            <span class="bottom-border-white">Kontakt podaci</span>
          </div>
          <div class="mb-2 white--text">
            <v-icon color="secondary" class="mr-2">mdi-email-outline</v-icon>
            protomicsport@gmail.com
          </div>
          <div class="mb-2 white--text">
            <v-icon color="secondary" class="mr-2">mdi-map-marker-outline</v-icon>
            Sjediste agencije: Zagreb/Düsseldorf
          </div>
          <div class="mb-2 white--text">
            <v-icon color="secondary" class="mr-2">mdi-phone-outline</v-icon>

          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="title mb-5 white--text">
            <span class="bottom-border-white">Društvene mreže</span>
          </div>
          <div class="mb-2 white--text">
            Pratite nas na društvenim mrežama:
          </div>
          <div>
            <v-btn small rounded  fab color="secondary" href="https://www.facebook.com/Menad%C5%BEment-i-komunikacija-u-sportu-358793308496728" target="_blank">
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn small rounded fab color="secondary" class="ml-3" href="https://www.instagram.com/menadzment_komunikacija_sport/" target="_blank">
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style>
.bottom-border-white {
  border-bottom: 4px solid #4d5d6a;
}
</style>
